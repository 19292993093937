@tailwind base;
@tailwind components;
@tailwind utilities;
.domin{
  font-family: "Domine", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.metro{
  font-family: 'Metropolis', sans-serif;
  font-weight: 400;
}
.metro-semi{
  font-family: 'Metropolis', sans-serif;
  font-weight: 600;
}
.metro-bold{
  font-family: 'Metropolis', sans-serif;
  font-weight: 700;
}
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer utilities {
  .heading {
    @apply text-secondary domin leading-tight  mb-10 lg:mb-12 text-[33px] md:text-[42px] lg:text-[52px] xl:text-[60px]
  }
  .para {
    @apply text-[19px] lg:text-[22px] xl:text-[24px] text-secondary-light metro leading-relaxed  mb-6 lg:mb-8;
  }
  .list {
    @apply text-[19px] flex items-center gap-4 lg:text-[22px] xl:text-[24px] text-secondary-light metro leading-relaxed  mb-6 ;
  }
  .sub-list {
    @apply w-1.5 h-[7px] border-secondary-light rounded-full bg-secondary-light;
  }
  .sub-heading {
    @apply text-secondary domin leading-tight  mb-10 lg:mb-12 text-[25px] md:text-[32px] lg:text-[42px] xl:text-[50px] ;
  }
}